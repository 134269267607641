import { printSnakeCase } from "./formatter"

export const kindCarOptions = t => {
  return [
    { value: "urbanite", label: t("common:kind.urbanite") },
    { value: "suv", label: t("common:kind.suv") },
    { value: "e-car", label: t("common:kind.e-car") },
    { value: "small-suv", label: t("common:kind.small-suv") },
    { value: "compact", label: t("common:kind.compact") },
    { value: "berlina", label: t("common:kind.berlina") },
    { value: "7seats", label: t("common:kind.7seats") },
    { value: "commercial", label: t("common:kind.commercial") },
    { value: "4x4", label: t("common:kind.4x4") }
  ]
}

export const preDeliveryCarOptions = t => [
  { value: "urbanite", label: t("common:preDeliveryCarOptionLabels.urbanite") },
  { value: "medium-compact", label: t("common:preDeliveryCarOptionLabels.medium-compact") },
  { value: "berlina", label: t("common:preDeliveryCarOptionLabels.berlina") },
  { value: "cargo-van", label: t("common:preDeliveryCarOptionLabels.cargo-van") },
  { value: "commercial-van", label: t("common:preDeliveryCarOptionLabels.commercial-van") }
]

export const fuelCarOptions = t => {
  return [
    { value: "petrol", label: t("common:fuel.petrol") },
    { value: "diesel", label: t("common:fuel.diesel") },
    { value: "electric", label: t("common:fuel.electric") },
    { value: "hybrid-petrol", label: t("common:fuel.hybrid-petrol") },
    { value: "hybrid-diesel", label: t("common:fuel.hybrid-diesel") },
    { value: "hybrid-lpg", label: t("common:fuel.hybrid-lpg") },
    { value: "hybrid-cng", label: t("common:fuel.hybrid-cng") },
    { value: "plugin-hybrid-electric", label: t("common:fuel.plugin-hybrid-electric") }
  ]
}

export const transmissionCarOptions = [
  { value: "automatic", label: "Automatic" },
  { value: "manual", label: "Manual" }
]

export const colorCarOptions = [
  { value: "white", label: "White" },
  { value: "black", label: "Black" },
  { value: "blue", label: "Blue" },
  { value: "red", label: "Red" },
  { value: "green", label: "Green" },
  { value: "grey", label: "Grey" },
  { value: "pink", label: "Pink" },
  { value: "purple", label: "Purple" },
  { value: "silver", label: "Silver" },
  { value: "orange", label: "Orange" },
  { value: "brown", label: "Brown" },
  { value: "bronze", label: "Bronce" },
  { value: "yellow", label: "Yellow" }
]

export const countryCodes = ["es", "fr", "pt", "it"]

export const countryCodeOptions = t => [
  { value: "es", label: t("common:country.es") },
  { value: "fr", label: t("common:country.fr") },
  { value: "pt", label: t("common:country.pt") },
  { value: "it", label: t("common:country.it") }
]

export const localeOptions = [
  { value: "es", label: "Spanish" },
  { value: "fr", label: "French" },
  { value: "pt", label: "Portuguese" },
  { value: "it", label: "Italian" }
]

export const environmentalLabelOptions = [
  { value: "zero", label: "Zero" },
  { value: "eco", label: "Eco" },
  { value: "c", label: "C" },
  { value: "b", label: "B" },
  { value: null, label: "-" }
]

export const activeOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Non Active" }
]

export const booleanOptions = t => [
  { value: true, label: t("true") },
  { value: false, label: t("false") }
]

export const roleOptions = t => [
  { value: "Staff", label: t("staffRole.Staff") },
  { value: "Sales", label: t("staffRole.Sales") },
  { value: "ScoringExpert", label: t("staffRole.ScoringExpert") },
  { value: "CustomerCare", label: t("staffRole.CustomerCare") }
]

export const sellingStatusCarOptions = [
  { value: "new", label: "New" },
  { value: "pre-owned", label: "Pre Owned" },
  { value: "flexible", label: "Flexible" },
  { value: "used", label: "Used" },
  { value: "km0", label: "Km 0" }
]

export const flexibleStatusCarOptions = [
  { value: "new", label: "New" },
  { value: "used", label: "Used" }
]

export const jobTypeOptions = [
  { value: "employee", label: "Employee" },
  { value: "freelance", label: "Freelance" },
  { value: "company", label: "Company" }
]

export const jobTypes = jobTypeOptions.map(option => option.value)

export const renewalTypeOptions = [
  { value: "S", label: "Sí" },
  { value: "N", label: "No" }
]

export const renewalTypes = renewalTypeOptions.map(option => option.value)

export const originOptions = [
  { value: "salon_automovil_bcn_21", label: "Salón Automóvil Barcelona 2021" },
  { value: "swipcar", label: "Swipcar" },
  { value: "micochederenting", label: "MiCocheDeRenting" },
  { value: "carrosderenting", label: "CarrosDeRenting" },
  { value: "inspiring benefits", label: "Inspiring benefits" },
  { value: "rentiner", label: "Rentiner" }
]

export const leasingDurations = [6, 12, 18, 24, 30, 36, 48, 60]
export const leasingKms = [5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000]

export const leasingDurationsOptions = [
  { value: 6, label: "6" },
  { value: 12, label: "12" },
  { value: 18, label: "18" },
  { value: 24, label: "24" },
  { value: 30, label: "30" },
  { value: 36, label: "36" },
  { value: 48, label: "48" },
  { value: 60, label: "60" }
]
export const leasingKmsOptions = [
  { value: 5000, label: "5.000" },
  { value: 10000, label: "10.000" },
  { value: 15000, label: "15.000" },
  { value: 20000, label: "20.000" },
  { value: 25000, label: "25.000" },
  { value: 30000, label: "30.000" },
  { value: 35000, label: "35.000" },
  { value: 40000, label: "40.000" },
  { value: 45000, label: "45.000" },
  { value: 50000, label: "50.000" },
  { value: 55000, label: "55.000" },
  { value: 60000, label: "60.000" }
]

export const cvOptions = [
  { value: 100, label: "100 cv" },
  { value: 120, label: "120 cv" },
  { value: 150, label: "150 cv" },
  { value: 200, label: "200 cv" }
]

export const leasingRequestStatusFields = [
  "globalStatus",
  "status",
  "offerStatus",
  "quotationStatus",
  "scoringStatus",
  "contractStatus",
  "closedStatus",
  "contractExtensionStatus"
]

export const leasingRequestGlobalStatuses = [
  "sales",
  "documentation_review",
  "scoring_documentation_pending",
  "scoring_under_review",
  "scoring_undecided",
  "approved",
  "contract_distributor",
  "contract_sent",
  "contract_undecided",
  "contract_signed",
  "won",
  "billable",
  "cancelled",
  "closed",
  "contract_extension_pending",
  "contract_extension_approved",
  "contract_extension_signed",
  "contract_extension_won"
]

export const leasingRequestStatuses = ["new", "scoring", "contract", "closed"]

export const leasingRequestQuotationStatuses = ["not_started", "requested", "sent", "signed"]

export const leasingRequestOfferStatuses = ["not_started", "requested", "received"]

export const leasingRequestScoringStatuses = ["not_started", "started", "undecided", "approved", "rejected"]

export const leasingRequestContractStatuses = ["not_started", "requested", "sent", "signed"]

export const leasingRequestClosedStatuses = ["not_defined", "won", "billable", "cancelled", "lost", "closed"]

export const leasingRequestContractExtensionStatuses = [
  "not_started",
  "pending",
  "approved",
  "rejected",
  "signed",
  "won"
]

export const leasingRequestOngoingContractExtensionStatuses = ["pending", "approved", "signed"]

export const clientStatuses = ["prospect", "created", "renovation", "follow_up", "closed"]

export const clientRequestsStatuses = ["created", "ongoing", "closed"]

export const clientRequestsStatusesOptions = t =>
  clientRequestsStatuses.map(s => {
    return { value: s, label: t(`clientRequest:status.${s}`) }
  })

export const leasingRequestGlobalStatusesOptions = t =>
  leasingRequestGlobalStatuses.map(s => {
    return { value: s, label: t(`common:leasingRequestGlobalStatus.${s}`) }
  })

export const leasingRequestStatusesOptions = leasingRequestStatuses.map(s => {
  return { value: s, label: printSnakeCase(s) }
})

export const leasingRequestQuotationStatusesOptions = t =>
  leasingRequestQuotationStatuses.map(s => {
    return { value: s, label: t(`leasingRequest:quotationStatuses.${s}`) }
  })

export const leasingRequestOfferStatusesOptions = t =>
  leasingRequestOfferStatuses.map(s => {
    return { value: s, label: t(`leasingRequest:offerStatuses.${s}`) }
  })

export const leasingRequestScoringStatusesOptions = t =>
  leasingRequestScoringStatuses.map(s => {
    return { value: s, label: t(`common:scoringStatus.${s}`) }
  })

export const leasingRequestClosedStatusesOptions = t =>
  leasingRequestClosedStatuses.map(s => {
    return { value: s, label: t(`common:closedStatus.${s}`) }
  })

export const leasingRequestContractExtensionStatusOptions = t =>
  leasingRequestContractExtensionStatuses.map(s => {
    return { value: s, label: t(`common:contractExtensionStatus.${s}`) }
  })

export const leasingRequestContractStatusesOptions = leasingRequestContractStatuses.map(s => {
  return { value: s, label: printSnakeCase(s) }
})

export const leasingRequestContractExtensionStatusesOptions = t =>
  leasingRequestContractExtensionStatuses.map(s => {
    return { value: s, label: t(`common:contractExtensionStatus.${s}`) }
  })

export const leadStatusesOptions = t => {
  return [
    { value: "pre_opportunity", label: t("lead:status.pre_opportunity") },
    { value: "new_opportunity", label: t("lead:status.new_opportunity") },
    { value: "documentation_requested", label: t("lead:status.documentation_requested") },
    { value: "abandoned", label: t("lead:status.abandoned") },
    { value: "rollback", label: t("lead:status.rollback") },
    { value: "converted", label: t("lead:status.converted") },
    { value: "scoring_rejected", label: t("lead:status.scoring_rejected") }
  ]
}

export const clientStatusesOptions = t =>
  clientStatuses.map(s => {
    return { value: s, label: t(`client:status.${s}`) }
  })

export const deliveryTimeOptions = [
  { value: 5, label: "< 5 weeks" },
  { value: 9, label: "< 9 weeks" },
  { value: 12, label: "< 12 weeks" },
  { value: 20, label: "< 20 weeks" }
]

export const monthlyPriceOptions = [
  { value: 300, label: "300€" },
  { value: 400, label: "400€" },
  { value: 500, label: "500€" },
  { value: 600, label: "600€" },
  { value: 800, label: "800€" },
  { value: 1000, label: "1.000€" },
  { value: 1500, label: "1.500€" },
  { value: 2000, label: "2.000€" }
]

export const binaryOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" }
]

export const AVAILABLE_LOCALES = ["es", "fr", "pt", "it"]

export const documentTypeOptions = [
  { value: "NIF", label: "DNI/NIF" },
  { value: "NIE", label: "NIE" }
]

export const fcaDocuments = ["id_photo_front", "id_photo_rear", "contract", "audit_trail_contract"]

export const emailTemplateResourceOptions = [
  { label: "Lead", value: "Lead" },
  { label: "Leasing Request", value: "LeasingRequest" },
  { label: "Client", value: "Client" },
  { label: "Client Issue", value: "ClientRequest" }
]

export const emailTemplateCategories = ["sales", "marketing", "operations", "renovations", "follow_up", "customer_care"]

export const emailTemplateCategoryOptions = t =>
  emailTemplateCategories.map(category => {
    return { value: category, label: t(`common:emailTemplateCategories.${category}`) }
  })

// https://www.twilio.com/docs/voice/client/errors
export const twilioErrorCodes = t => ({
  13224: t("twilioErrors.wrongNumber"),
  13225: t("twilioErrors.wrongNumber"),
  13227: t("twilioErrors.wrongNumber"),
  16001: t("twilioErrors.conferenceError"),
  16025: t("twilioErrors.conferenceParticipantError"),
  21220: t("twilioErrors.conferenceError"),
  31001: t("twilioErrors.invalidSession"),
  31201: t("twilioErrors.noMic"),
  31202: t("twilioErrors.invalidSession"),
  31204: t("twilioErrors.invalidSession"),
  31205: t("twilioErrors.invalidSession"),
  31207: t("twilioErrors.invalidSession"),
  31208: t("twilioErrors.noMic")
})

// https://www.twilio.com/docs/voice/insights/call-quality-events-twilio-client-sdk#error-and-warning-events
export const twilioWarnings = [
  "high-rtt",
  "low-mos",
  "high-jitter",
  "high-packet-loss",
  "high-packets-lost-fraction",
  "low-bytes-received",
  "low-bytes-sen"
]

export const promotionOptions = t => [
  { value: "insipiring_benefits", label: t("common:promotionOptions.insipiring_benefits") },
  { value: "collectivos_vip", label: t("common:promotionOptions.collectivos_vip") },
  { value: "client_referral", label: t("common:promotionOptions.client_referral") },
  { value: "free_delivery", label: t("common:promotionOptions.free_delivery") },
  { value: "multiple_cars", label: t("common:promotionOptions.multiple_cars") },
  { value: "swipcar_referral", label: t("common:promotionOptions.swipcar_referral") }
]

export const winProbabilityOptions = [
  { value: 75, label: "75%" },
  { value: 50, label: "50%" },
  { value: 25, label: "25%" },
  { value: null, label: "-" }
]

export const leasingRequestCloseReasons = [
  "age",
  "duplicated",
  "postponed",
  "no_stock",
  "redirect",
  "cross_with_broker",
  "regrets",
  "competitor",
  "do_not_answer",
  "conditions",
  "sales_issues",
  "contract_disagreement",
  "distrust",
  "do_not_sign_gdpr",
  "do_not_have_guarantee",
  "do_not_have_extra_documentation",
  "payment_capacity",
  "incidence",
  "bad_gdpr_sign",
  "other"
]

export const leasingRequestCloseReasonsOptions = t =>
  leasingRequestCloseReasons.map(s => {
    return { value: s, label: t(`common:closeReasonOptions.${s}`) }
  })

export const leasingRequestUndecidedReasonOptions = t =>
  ["do_not_have_extra_documentation", "bad_gdpr_sign", "more_documentation_needed"].map(s => ({
    value: s,
    label: t(`common:closeReasonOptions.${s}`)
  }))

export const leasingRequestPostponedDaysOptions = t => {
  return [7, 15, 30, 60, 90].map(d => {
    return { value: d, label: t(`common:day_plural`, { count: d }) }
  })
}

export const leasingRequestCreatedReasonOptions = t => [
  { value: "new", label: t("common:createdReasonOptions.new") },
  { value: "more_cars", label: t("common:createdReasonOptions.more_cars") },
  { value: "renovation", label: t("common:createdReasonOptions.renovation") },
  { value: "referred", label: t("common:createdReasonOptions.referred") }
]

export const leasingRequestNegotiationStatusOptions = t => [
  { value: "not_billable", label: t("common:negotiationStatusOptions.not_billable") },
  { value: "inactive", label: t("common:negotiationStatusOptions.inactive") },
  { value: "active", label: t("common:negotiationStatusOptions.active") },
  { value: "lost", label: t("common:negotiationStatusOptions.lost") }
]

export const leasingRequestNegotiationLostReasonOptions = t => [
  { value: "renovation", label: t("common:negociationLostReasonOptions.renovation") },
  { value: "no_extend_or_renove", label: t("common:negociationLostReasonOptions.no_extend_or_renove") },
  { value: "expired", label: t("common:negociationLostReasonOptions.expired") },
  { value: "not_formalized", label: t("common:negociationLostReasonOptions.not_formalized") }
]

export const clientRequestCategoryOptions = t => [
  { value: null, label: "-" },
  { value: "delivery_delay", label: t("clientRequestCategories.delivery_delay") },
  { value: "delivery_info", label: t("clientRequestCategories.delivery_info") },
  { value: "delivery_direction_update", label: t("clientRequestCategories.delivery_direction_update") },
  { value: "delivery_transport_pending", label: t("clientRequestCategories.delivery_transport_pending") },
  { value: "wrong_payment", label: t("clientRequestCategories.wrong_payment") },
  { value: "deposit_return", label: t("clientRequestCategories.deposit_return") },
  { value: "cancellation", label: t("clientRequestCategories.cancellation") },
  { value: "other", label: t("clientRequestCategories.other") }
]

export const clientRequestCategories = t => [
  { value: "pre_delivery", label: t("clientRequest:categories.pre_delivery") },
  { value: "delivery", label: t("clientRequest:categories.delivery") },
  { value: "cancellation", label: t("clientRequest:categories.cancellation") },
  { value: "product_issue", label: t("clientRequest:categories.product_issue") },
  { value: "bonus", label: t("clientRequest:categories.bonus") },
  { value: "redirect", label: t("clientRequest:categories.redirect") }
]

export const clientRequestSubcategories = t => ({
  pre_delivery: [
    { value: "client_pending", label: t("clientRequest:subcategories.pre_delivery.client_pending") },
    { value: "distributor_pending", label: t("clientRequest:subcategories.pre_delivery.distributor_pending") },
    { value: "delay", label: t("clientRequest:subcategories.pre_delivery.delay") }
  ],
  delivery: [
    { value: "info", label: t("clientRequest:subcategories.delivery.info") },
    { value: "delay", label: t("clientRequest:subcategories.delivery.delay") },
    { value: "early", label: t("clientRequest:subcategories.delivery.early") },
    { value: "postpone", label: t("clientRequest:subcategories.delivery.postpone") },
    { value: "take_away", label: t("clientRequest:subcategories.delivery.take_away") },
    { value: "car_error", label: t("clientRequest:subcategories.delivery.car_error") }
  ],
  cancellation: [{ value: "default", label: t("clientRequest:subcategories.cancellation.default") }],
  product_issue: [
    { value: "wrong_payment", label: t("clientRequest:subcategories.product_issue.wrong_payment") },
    { value: "steal", label: t("clientRequest:subcategories.product_issue.steal") },
    { value: "car_check", label: t("clientRequest:subcategories.product_issue.car_check") },
    { value: "client_area", label: t("clientRequest:subcategories.product_issue.client_area") }
  ],
  bonus: [{ value: "default", label: t("clientRequest:subcategories.bonus.default") }],
  redirect: [
    { value: "renovations", label: t("clientRequest:subcategories.redirect.renovations") },
    { value: "backoffice", label: t("clientRequest:subcategories.redirect.backoffice") },
    { value: "sales_closers", label: t("clientRequest:subcategories.redirect.sales_closers") }
  ]
})
