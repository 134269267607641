import React from "react"
import { Divider, Drawer, List, ListSubheader } from "@material-ui/core"
import {
  Assignment,
  Ballot,
  ChevronLeft,
  CloudQueue,
  Drafts,
  Email,
  Face,
  Hearing,
  List as ActionList,
  List as ListIcon,
  Loyalty,
  PowerSettingsNew,
  ShoppingCart,
  ShowChart,
  Sms,
  SupervisorAccount,
  TimeToLeave as NotificationTimeToLeave,
  Visibility,
  Web,
  Work,
  Forum,
  CreateNewFolder
} from "@material-ui/icons"
import makeStyles from "@material-ui/styles/makeStyles"
import IconButton from "@material-ui/core/IconButton"
import styled from "styled-components"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { logout } from "../../../services/accountService"
import { signOutAccount, toggleLeftMenu } from "../../../actions"
import SingleListItem from "./SingleListItem"
import CollapseListItem from "./CollapseListItem"

const useStyles = makeStyles({
  drawerPaper: {
    width: 250,
    background: "#282a39",
    justifyContent: "space-between"
  }
})

const DrawerStyled = styled(Drawer)`
  width: 250px;
  flex-shrink: 0;
`

const RevisionInfo = styled.span`
  color: white;
  opacity: 0.25;
  text-align: center;
  margin: 0;
  display: block;
`

const LogoutBlock = styled.p`
  text-align: center;
  position: relative;
  bottom: 0;
  svg {
    color: white;
    cursor: pointer;
  }
`

function LeftMenu() {
  const { account, leftMenuOpen } = useSelector(mapStateToProps, shallowEqual)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  if (!account) return null

  return (
    <DrawerStyled
      open={leftMenuOpen}
      variant="persistent"
      anchor="left"
      onClose={() => dispatch(toggleLeftMenu())}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <List>
        <ListSubheader>
          <IconButton onClick={() => dispatch(toggleLeftMenu())}>
            <ChevronLeft className={classNames("align-middle", classes.icon)} />
          </IconButton>
        </ListSubheader>
        <SingleListItem
          title="Dashboard"
          action={() => history.push("/dashboard")}
          icon={<Assignment className={classes.icon} />}
        />
        <Divider />
        <CollapseListItem
          title="Staff"
          access={account.hasAbility("manage_staff")}
          icon={<SupervisorAccount className={classes.icon} />}
        >
          <SingleListItem
            title="List"
            access={account.hasAbility("manage_staff")}
            action={() => history.push("/staff")}
            icon={<ListIcon className={classes.icon} />}
          />
          <SingleListItem
            title="Abilities"
            access={account.hasAbility("manage_abilities_staff")}
            action={() => history.push("/staff/abilities")}
            icon={<Visibility className={classes.icon} />}
          />
          <SingleListItem
            title="Task Assignment"
            access={account.hasAbility("manage_tasks_staff")}
            action={() => history.push("/staff/task-assignment")}
            icon={<Work className={classes.icon} />}
          />
          <SingleListItem
            title="Online"
            access={account.hasAbility("view_online_staff")}
            action={() => history.push("/staff/online")}
            icon={<CloudQueue className={classes.icon} />}
          />
          <SingleListItem
            title="Quality"
            access={account.hasAbility("coach_staff")}
            action={() => history.push("/staff/quality")}
            icon={<Hearing className={classes.icon} />}
          />
        </CollapseListItem>
        <SingleListItem
          title="Transactional Emails"
          access={account.hasAbility("read_transactional_emails")}
          action={() => history.push("/transactional-emails")}
          icon={<Email className={classes.icon} />}
        />
        <SingleListItem
          title="Transactional SMS"
          access={account.hasAbility("read_transactional_sms")}
          action={() => history.push("/transactional-sms")}
          icon={<Sms className={classes.icon} />}
        />
        <SingleListItem
          title="Email Templates"
          access={account.hasAbility("manage_email_templates")}
          action={() => history.push("/email-templates")}
          icon={<Drafts className={classes.icon} />}
        />
        <SingleListItem
          title="Analytics"
          access={account.hasAbility("view_analytics")}
          action={() => history.push("/analytics")}
          icon={<ShowChart className={classes.icon} />}
        />
        <SingleListItem
          title="Analytics (BETA)"
          access={account.hasAbility("view_analytics")}
          action={() => history.push("/analytics-beta")}
          icon={<ShowChart className={classes.icon} />}
        />
        <CollapseListItem
          title="Cars Providers"
          access={account.hasAbility("read_cars")}
          icon={<CreateNewFolder className={classes.icon} />}
        >
          <SingleListItem
            title="Dealers"
            icon={<SupervisorAccount className={classes.icon} />}
            access={account.hasAbility("manage_distributors")}
            action={() => history.push("/dealers")}
          />
          <SingleListItem
            title="Distributors"
            icon={<SupervisorAccount className={classes.icon} />}
            access={account.hasAbility("manage_distributors")}
            action={() => history.push("/distributors")}
          />
        </CollapseListItem>
        <CollapseListItem
          title="Cars"
          access={account.hasAbility("read_cars")}
          icon={<NotificationTimeToLeave className={classes.icon} />}
        >
          <SingleListItem
            access={account.hasAbility("manage_brands")}
            title="Brands & Models"
            icon={<ActionList className={classes.icon} />}
            action={() => history.push("/brands")}
          />
          <SingleListItem
            title="List"
            access={account.hasAbility("read_cars")}
            action={() => history.push("/cars")}
            icon={<ListIcon className={classes.icon} />}
          />
          <SingleListItem
            title="Landings"
            access={account.hasAbility("update_cars")}
            action={() => history.push("/cars/landings")}
            icon={<Web className={classes.icon} />}
          />
        </CollapseListItem>
        <SingleListItem
          title="Leads"
          access={account.hasAbilities(["read_leads", "manage_own_leads", "manage_country_leads", "manage_leads"])}
          icon={<Face className={classes.icon} />}
          action={() => history.push("/leads")}
        />
        <SingleListItem
          title="Leasing Requests"
          access={account.hasAbilities([
            "read_leasing_requests",
            "manage_own_leasing_requests",
            "manage_country_leasing_requests",
            "manage_leasing_requests"
          ])}
          icon={<Ballot className={classes.icon} />}
          action={() => history.push("/leasing-requests")}
        />
        <SingleListItem
          title={t("clients")}
          access={account.hasAbilities(["read_clients", "manage_clients", "manage_country_clients"])}
          icon={<Loyalty className={classes.icon} />}
          action={() => history.push("/clients")}
        />
        <SingleListItem
          title={t("clientRequest:clientRequests")}
          access={account.hasAbilities([
            "read_client_requests",
            "manage_client_requests",
            "manage_country_client_requests"
          ])}
          icon={<Forum className={classes.icon} />}
          action={() => history.push("/client-requests")}
        />
        <SingleListItem
          title="Sales"
          access={account.hasAbility("view_sales")}
          icon={<ShoppingCart className={classes.icon} />}
          action={() => history.push("/sales")}
        />
      </List>
      <LogoutBlock>
        <PowerSettingsNew
          fontSize="large"
          onClick={() => {
            dispatch(signOutAccount())
            logout()
          }}
        />
        <RevisionInfo>{process.env.REACT_APP_GIT_INFO.substring(0, 8)}</RevisionInfo>
      </LogoutBlock>
    </DrawerStyled>
  )
}

const mapStateToProps = state => {
  const { accountReducer } = state

  const {
    item: account,
    visualConfiguration: { leftMenuOpen }
  } = accountReducer

  return { account, leftMenuOpen }
}

export default LeftMenu
