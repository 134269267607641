import PropTypes from "prop-types"
import React from "react"
import TableCell from "@material-ui/core/TableCell"
import styled from "styled-components"
import Price from "./Price"

const TableCellWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

export default function Plan(props) {
  const { id, car, duration, kmYear, minimumPrice, ActionJSX } = props

  const priceRange = car.priceRanges.find(pr => pr.duration === duration && pr.kmYear === kmYear)

  if (!priceRange) return <TableCell />

  return (
    <TableCell>
      <TableCellWrapper>
        <Price priceRange={priceRange} minimumPrice={minimumPrice} />
        {ActionJSX && <ActionJSX id={id} car={car} priceRange={priceRange} />}
      </TableCellWrapper>
    </TableCell>
  )
}

Plan.propTypes = {
  ActionJSX: PropTypes.func,
  car: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  kmYear: PropTypes.number.isRequired,
  minimumPrice: PropTypes.number.isRequired
}

Plan.defaultProps = {
  ActionJSX: null
}
